<template>
  <div class="homepage">
    <!-- 导航栏 -->
    <el-header class="navbar" style="background-color: #FFFFFF; height: 76px;">
      <div class="logo" style="cursor: pointer;" @click="toHome">
        <el-image :src="require('../assets/logo.png')"></el-image>
      </div>
      <div class="title" style="cursor: pointer;" @click="toHome">零碳在线</div>
      <div class="menu">
        <!-- default-active="/home/homebody" -->
        <!-- style="border-bottom: none;" -->
        <el-menu mode="horizontal"
                 style="border-bottom: none;height: 76px; display: flex; flex-direction: row; justify-content: space-around;"
                 :default-active="this.$route.path" router>
          <el-menu-item style="line-height: 76px;height: 76px;" index="/home/homebody">首页</el-menu-item>
          <el-menu-item style="line-height: 76px;height: 76px;" index="/home/DigitalHuman">零碳数智人</el-menu-item>
          <el-menu-item style="line-height: 76px;height: 76px;" index="/home/activity">活动/课程</el-menu-item>
          <!--          <el-menu-item style="line-height: 76px;height: 76px;" index="/home/class">课程</el-menu-item>-->
          <el-menu-item style="line-height: 76px;height: 76px;" index="/home/productintro">零碳科技产品</el-menu-item>
          <el-menu-item style="line-height: 76px;height: 76px;" index="/home/aboutus">关于我们</el-menu-item>
          <el-menu-item style="line-height: 76px;height: 76px;" index="/home/login">加入我们</el-menu-item>
        </el-menu>
      </div>
    </el-header>

    <!-- 内容区域  这里的router-view内容：/home/xxx -》根据router的index.js映射的组件  -->
    <div class="contain">
      <router-view></router-view>
    </div>
    <!-- <message-box></message-box> -->
    <!-- 页脚 -->
    <!-- <el-footer class="footer" style="background-color: #333333;height: 200px;">
      <div class="footer-left">
        <div class="footer-item">上海人大人科技创新服务有限公司</div>
        <div class="footer-item">地址：黄埔区新一百大厦</div>
        <div class="footer-item">电话</div>
        <div class="footer-item">邮箱</div>
      </div>
      <div class="footer-right">
        <div class="footer-icon">
          <el-image :src="require('@/assets/logo.png')"></el-image>
          <div class="footer-text">微信公众号</div>
        </div>
        <div class="footer-icon">
          <el-image :src="require('@/assets/logo.png')"></el-image>
          <div class="footer-text">APP下载</div>
        </div>
      </div>
    </el-footer> -->
    <el-footer class="footer" style="background-color: #333333;height: 240px;">
      <div class="footer-left">
        <!-- 底部页脚左边内容 -->
        <div class="footer-item">{{ company }}</div>
        <div class="footer-item">电话: &nbsp;021-53828290</div>
        <div class="footer-item">邮箱: &nbsp;shdtxh@chinaver.net</div>
        <div class="footer-item">地址: &nbsp;上海市黄浦区淮海中路138号上海广场3层</div>
        <div v-if="net" class="footer-item">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007577"
             style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
              src="../assets/icp.png" style="float:left;"/>
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">沪公网安备
              31010102007577号</p></a>
        </div>
        <div class="footer-item" style="color: #c00000; ">
          {{ icp }}
        </div>
        <div class="footer-item">©2023 {{ company }} 版权所有</div>
      </div>
      <!-- <div class="footer-middle">
        <div class="footer-item" style="color: #c00000; ">
          沪ICP备18048400号-1
        </div>
        <div class="footer-item">©2023 上海人大人科学发展研究院 版权所有</div>
      </div> -->
      <div class="footer-right">
        <!-- 底部页脚右边图标 -->
        <div class="footer-icon">
          <el-image :src="require('@/assets/qrcode2.png')"></el-image>
          <div class="footer-text">微信公众号</div>
        </div>
      </div>
    </el-footer>
  </div>
</template>

<script>
import MessageBox from '@/components/MessageBox.vue';

export default {
  // name: "PageHome",
  components: {
    MessageBox,
  },
  data() {
    return {
      activeMenu: 'HomeBody', // 默认选中的菜单项
      company: '',
      icp: '',
      net: false, //通过.net访问网站时显示沪公网安备
      // 动态切换子组件
      // comName: "HomeBody"
    };
  },
  mounted() {
    // 获取当前网页的域名
    const currentDomain = window.location.hostname;
    switch (currentDomain) {
      case 'www.chinaver.cn':
      case 'www.chinaver.org.cn':
        this.company = '上海零碳在线投资股份有限公司';
        this.icp = '沪ICP备18048400号-1';
        break;
      case 'www.chinaver.net':
        this.company = '上海市低碳科技与产业发展协会';
        this.icp = '沪ICP备2023019779号';
        break;
      default:
        this.company = '上海人大人科技创新服务有限公司';
        this.icp = '沪ICP备15054605号-1';
    }
    //// 判断域名的后缀
    //if (currentDomain.endsWith('.net')) {
    //  this.company = '上海市低碳科技与产业发展协会'
    //  this.icp = '沪ICP备2023019779号'
    //  this.net = true
    //} else {
    //  this.company = '上海零碳在线投资股份有限公司'
    //  this.icp = '沪ICP备18048400号-1'
    //}
  },
  methods: {
    toHome() {
      if (this.$router.currentRoute.fullPath === '/home/homebody') {
        return;
      }
      this.$router.push('/home/homebody');
    },
  },
};
</script>

<style scoped>
/* 样式表 */
.homepage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: -8px;
}

.navbar {
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex: 0;
}

/* .logo {
  font-size: 24px;
  color: #000000;
  margin-left: 5%;
} */
.logo {
  font-size: 24px;
  color: #000000;
  flex-shrink: 0;
  margin-left: 5%;
}

.title {
  line-height: 76px;
  font-size: 20px;
  flex-shrink: 0;
  color: #333333;
  font-weight: bolder;
}

.menu {
  flex-grow: 1;
}

/* .title {
  height: 76px;
  line-height: 76px;
  width: 90px;
  font-size: 20px;
  color: #333333;
  font-weight: bolder;
  margin-left: 1%;
} */
/* .menu {
  margin-left: 15%;
  width: 70%;
} */
.contain {
  background-color: #f9f9f9;
  flex: 1;
}

/* .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.footer {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  color: #ffffff;
}

/* @media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
  }
} */
.footer-left {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-middle {
  margin-top: 110px;
  /* display: grid;
  place-items: center; */
  padding-top: 30px;
  text-align: center;
}

.footer-item {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-right {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-icon {
  width: 120px;
  height: 120px;
  /* margin-left: 70px; */
}

.footer-text {
  margin-top: 10px;
  width: 120px;
  text-align: center;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #007aff;
}

.el-menu-item {
  font-size: 18px;
  font-weight: bolder;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #409eff;
  color: #007aff;
}
</style>
