<template>
  <div class="product_intro">
    <!-- <h1 class="temp">暂无内容</h1> -->
    <!-- 工作站 -->
    <div class="big-image">
      <img src="@/assets/productintro/workstation/main.png" alt="" class="main-image" />
      <div class="left-image">
        <div class="qr-code">
          <p>线上体验</p>
          <img src="@/assets/productintro/workstation/xcx.jpg" alt="线上体验" />
        </div>
        <div class="qr-code">
          <p>购买渠道</p>
          <img src="@/assets/productintro/workstation/wd.png" alt="购买渠道" />
        </div>
        <div class="qr-code">
          <p>咨询合作</p>
          <img src="@/assets/productintro/workstation/wx.jpg" alt="咨询合作" />
        </div>
        <div class="qr-code">
          <p>建议/反馈</p>
          <img src="@/assets/productintro/workstation/yj.png" alt="建议/反馈" />
        </div>
      </div>
    </div>
    <div class="big-image">
      <img src="@/assets/productintro/earphone/main.png" alt="" class="main-image" />
      <div class="left-image">
        <div class="qr-code">
          <p>线上体验</p>
          <img src="@/assets/productintro/earphone/xcx.jpg" alt="线上体验" />
        </div>
        <div class="qr-code">
          <p>购买渠道</p>
          <img src="@/assets/productintro/earphone/wd.png" alt="购买渠道" />
        </div>
        <div class="qr-code">
          <p>咨询合作</p>
          <img src="@/assets/productintro/earphone/wx.jpg" alt="咨询合作" />
        </div>
        <div class="qr-code">
          <p>建议/反馈</p>
          <img src="@/assets/productintro/earphone/jy.png" alt="建议/反馈" />
        </div>
      </div>
    </div>
    <!-- 以下内容为写死，暂时注释 -->
    <!-- <div class="product_list">
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>
  
  <script>
export default {
  name: "ProductIntro",
  data() {
    return {};
  },
  mounted() {}
};
</script>
  
  <style lang="less" scoped>
.product_intro {
  min-height: 450px;
  background-color: white;
}
.temp {
  text-align: center;
  font-size: 72px;
  color: #abb2a9;
  margin: 150px auto 0;
}
.big-image {
  display: flex; /* 使用Flexbox布局 */
  width: 100%; /* 父容器宽度 */
  align-items: flex-start; /* 子元素从顶部开始排列 */
}

.main-image {
  width: 66.66%; /* 主图宽度占2/3 */
  height: auto; /* 保持图片比例 */
}

.left-image {
  width: 33.33%; /* 左侧图片宽度占1/3 */
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap; /* 允许子元素换行 */
  justify-content: space-between; /* 子元素之间的间距 */
  align-items: flex-start; /* 子元素从顶部开始排列 */
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 10px); /* 每个二维码盒子占据50%的宽度，减去间距 */
  margin-bottom: 20px; /* 图片之间的垂直间距 */
}

.qr-code img {
  width: 80%; /* 二维码图片宽度 */
  height: 80%; /* 二维码图片高度 */
}

.qr-code p {
  margin-top: 5px; /* 文本与图片之间的间距 */
  text-align: center;
}
// 以下内容为写死，暂时注释
// @imgWidth: 306px;
// .product_intro {
//   padding: 60px;
//   background-color: #f9f9f9;
// }
// .product_list {
//   display: flex;
//   flex-wrap: wrap;
//   .product_item {
//     margin-top: 18px;
//     &:nth-child(-n + 3) {
//       margin-right: 18px;
//     }
//     &:nth-child(-n + 7):nth-child(n + 2) {
//       margin-right: 18px;
//     }
//     .item_pic {
//       img {
//         // vertical-align: center;
//         vertical-align: bottom;
//       }
//     }
//     .item_text {
//       width: @imgWidth;
//       //   height: 84px;
//       padding: 20px 15px;
//       background-color: #fff;
//       box-sizing: border-box;
//       // p {
//       //   // width: @imgWidth;
//       // }
//     }
//   }
// }
</style>