<template>
  <div class="home_body">
    <div class="body_main">
      <!-- 分上下两部分 -->
      <!-- 上部分 -->
      <div class="body_top">
        <div class="top_left" @click="$router.push('/home/DigitalHuman')">
<!--          <img src="@/assets/top-left-img.png" alt="" />-->
<!--          <img style="width: 97%; height: 100%;" src="https://qiniu.chinardr.net/pc-digitalHuman.png"  alt="" />-->
          <img style="width: 97%; height: 100%;" src="../assets/home/pc-digitalHuman.png"  alt="" />
        </div>
        <div class="top_right">
          <!-- 活动 -->
          <div class="top_right_activity show_item" @click="$router.push('/home/activity')">
<!--            <div class="activity abs_box">活动</div>-->
          </div>
          <!-- 课程 -->
          <!--<div class="top_right_class show_item" @click="$router.push('/home/class')">-->
            <div class=" show_item" @click="goToExternalLink" :class="isCn ? 'top_right_class' : 'top_right_class_MY'">
<!--            <div class="class abs_box">课程</div>-->
          </div>
        </div>
      </div>
      <!-- 下部分 -->
      <div class="body_bottom">
        <div class="bottom_list">
          <div class="bottom_list_contact bottom_item show_item" @click="$router.push('/home/login')">
<!--            <div class="contact abs_box">加入我们</div>-->
          </div>
          <div class="bottom_list_aboutus bottom_item show_item" @click="$router.push('/home/aboutus')">
<!--            <div class="aboutus abs_box">关于我们</div>-->
          </div>
          <div class="bottom_list_prointro bottom_item show_item" @click="$router.push('/home/productintro')">
<!--            <div class="prointro abs_box">产品介绍</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBody",
  data() {
    return {
      isCn : true
    };
  },
  mounted() { },
  created() {
    this.getLoginCountry();
  },
  methods: {
    getLoginCountry() {
      this.$http
          .get("user/getLoginCountry", {time: new Date().getTime() })
          .then(res => {
            if(res.result.res){
              if(res.result.data.country_code === 'CN') {
                this.isCn = true
              }else {
                this.isCn = false
              }
            }
          });
    },
    goToExternalLink() {
      window.open('https://www.rdrstartup.com/', '_blank');
    }
  }
};
</script>

<style lang="less" scoped>
@item_width: 440px;
@item_heigth: 254px;
@margin: 30px;
@item_abs_width: 185px;
@item_abs_height: 50px;

.body_main {
  padding: 60px 30px;
  margin: 0 auto;
  width: 1380px;
}

.home_body {
  background-color: #f9f9f9;
}

.body_top {
  display: flex;

  .top_left {
    cursor: pointer;
    img {
      margin-right: @margin;
      vertical-align: bottom;
    }
  }

  .top_right {
    .top_right_activity {
      cursor: pointer;
       background: url("../assets/home/activity.jpg") no-repeat;
      //background: url("https://qiniu.chinardr.net/v4/btn1.png") no-repeat;
      // background: url("/src/assets/activity_contact.png") no-repeat;
      width: @item_width;
      height: @item_heigth;
      margin-bottom: @margin;
      // .activity {
      // }
    }

    .top_right_class {
      cursor: pointer;
      background: url("../assets/home/RDRAI.jpg") no-repeat;
      //background: url("https://qiniu.chinardr.net/v4/btn2.png") no-repeat;
      width: @item_width;
      height: @item_heigth;
      // .class {
      // }
    }
    .top_right_class_MY {
      cursor: pointer;
      background: url("../assets/home/MYAI.jpg") no-repeat;
      //background: url("https://qiniu.chinardr.net/v4/btn2.png") no-repeat;
      width: @item_width;
      height: @item_heigth;
      // .class {
      // }
    }
  }
}

.body_bottom {
  margin-top: @margin;

  .bottom_list {
    display: flex;
    flex-wrap: wrap;

    .bottom_item {
      &:nth-child(-n + 2) {
        margin-right: @margin;
      }
    }

    .bottom_list_contact {
      cursor: pointer;
      background: url("../assets/home/JoinUs.jpg") no-repeat;
      //background: url("https://qiniu.chinardr.net/v4/btn4.png") no-repeat;
      width: @item_width;
      height: @item_heigth;

      .contact {
        // color: #b1afafc5;
      }

      // cursor: pointer;
      // .contact {
      // }
    }

    .bottom_list_aboutus {
      cursor: pointer;
      background: url("../assets/home/about1.jpg") no-repeat;
      //background: url("/src/assets/class_aboutus.png") no-repeat;
      width: @item_width;
      height: @item_heigth;
      // .aboutus {
      // }
    }

    .bottom_list_prointro {
      cursor: pointer;
      background: url("../assets/home/product.jpg") no-repeat;
      //background: url("https://qiniu.chinardr.net/v4/btn3.png") no-repeat;
      width: @item_width;
      height: @item_heigth;
      // .prointro {
      // }
    }
  }
}

.show_item {
  position: relative;

  .abs_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: @item_abs_width;
    height: @item_abs_height;
    box-sizing: border-box;
    text-align: center;
    border: 1.5px solid #fff;
    color: #fff;
    line-height: @item_abs_height;
  }
}
</style>
